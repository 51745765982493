.contact_class{
    background-color: var(--bg-color);
    color: var(--text-color);
}

.contact_container{
    padding-top: 100px;
}

.t_border {
    background-color: var(--text-color-2);
    border: none;
    height: 2px;
}

.form-class{
    background-color: var(--bg-color);
    color: var(--text-color);
    border: 1px solid var(--text-color);
    width: 250px;
}

.message-class{
    background-color: var(--bg-color);
    color: var(--text-color);
    border: 1px solid var(--text-color);
    width: 468px;
    height: 8em;
}

.btn-class{
    background-color: white;
    color: var(--bg-color);
    transition: all 0.3s;
    border-radius: 5px;
    border: none;
    box-shadow: 5px 5px 8px rgba(102,187,106,0.4);
}

.btn-class:hover{
    transform: scale(1.1);
    background-color: var(--text-color-2);
    box-shadow: none;
}

input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px var(--bg-color) inset;
    -webkit-text-fill-color: var(--text-color);
}

@media (max-width: 992px){
    .form-class, .message-class{
        width: 350px;
    }
}