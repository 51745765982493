@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');

:root {
  --bg-color: #050203;
  --primary-color: #0c0c0c;
  --text-color: #fff;
  --text-color-2: rgb(102, 187, 106);
}

html,
body {
    background-color: var(--bg-color);
    color: var(--text-color);
}

h2,
h3{
  font-family: "Rajdhani", sans-serif;
}

h1,
p{
  font-family: monospace;
}

a:-webkit-any-link {
  text-decoration: none;
  color: inherit;
}